function playVideo() {
	document.querySelector(".featherlight-content video").play()
}

function typeform() {
	let typeform = document.querySelectorAll('.typeform'),
		currentUrl = window.location.href	

	typeform.forEach(function(form) {
		let typeformId = 'MuhbY8rX',
			labelText = form.innerText,
			sectionTitle = form.dataset.section
		let { toggle } = window.tf.createPopup(typeformId, {
			hidden: {
				source_url: currentUrl,
				button_label: labelText,
				section_title: sectionTitle,
			},
			shareGaInstance: true,
			onSubmit: (event) => {
				document.body.classList.remove('noscroll')
				parallelCursor.framesMouseLeave()
				dataLayer.push({
					'event' : 'typeform-submit',
					'trigger_label' : labelText,
					'page_url' : currentUrl,
					'section_title' : sectionTitle
				})
			},
			onClose: (event) => {
				document.body.classList.remove('noscroll')
				parallelCursor.framesMouseLeave()
				dataLayer.push({
					'event' : 'typeform-close'
				})
			},
			onReady: (event) => {
				parallelCursor.framesMouseOver()
				document.body.classList.add('noscroll')
				dataLayer.push({
					'event' : 'typeform-open',
					'trigger_label' : labelText,
					'page_url' : currentUrl,
					'section_title' : sectionTitle
				})
			}
		})
		form.onclick = toggle
	})
}

function typeformEmbed() {
	let typeformSection = document.getElementById('typeEmbed'),
		currentUrl = window.location.href, 
		typeformId = 'MuhbY8rX',
		labelText = 'Get In Touch Embed',
		sectionTitle = 'Get In Touch Embed'
	if(typeformSection) {
		window.tf.createWidget(typeformId, {
			container: document.getElementById('typeEmbed'),
			autoFocus: true,
			disableScroll: true,
			hidden: {
				source_url: currentUrl,
				button_label: labelText,
				section_title: sectionTitle,
			},
			shareGaInstance: true,
			onSubmit: (event) => {
				document.body.classList.remove('noscroll')
				parallelCursor.framesMouseLeave()
				dataLayer.push({
					'event' : 'typeform-submit',
					'trigger_label' : labelText,
					'page_url' : currentUrl,
					'section_title' : sectionTitle
				})
			},
			onReady: (event) => {
				parallelCursor.framesMouseOver()
				document.body.classList.add('noscroll')
				dataLayer.push({
					'event' : 'typeform-open',
					'trigger_label' : labelText,
					'page_url' : currentUrl,
					'section_title' : sectionTitle
				})
			}
		})
	}
}

window.addEventListener("load", function () {
	//typeform() // ADD TYPEFORM to .typeform
	//typeformEmbed() // Embed TYPE to #typeEmbed
	teamScroll() // TEAM SECTION
	scrollText() //SLIDING TEXT HOME SECTIONS
	isLoaded()
})

function typeformLoaded() {
	parallelCursor.framesMouseOver()
}

function isLoaded() {
	document.body.classList.add("is-loaded");
}

document.addEventListener("DOMContentLoaded", function() {
	var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
	
	if ("IntersectionObserver" in window) {
		var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(video) {
				if (video.isIntersecting) {
					for (var source in video.target.children) {
						var videoSource = video.target.children[source];
						if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
							videoSource.src = videoSource.dataset.src;
						}
					}
					video.target.load();
					video.target.classList.remove("lazy");
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});
		lazyVideos.forEach(function(lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	}
});

{
	const showreel_video_preview = document.querySelector('#showreel_video_preview')
	if(showreel_video_preview) {
		showreel_video_preview.playbackRate = 4
	}
}

//jQuery(document).ready(function() {


//	width = jQuery(document).width();
//    if (width > 992){
//		particlesJS.load('particles-js', '/particles.json', function() {
//    	});
//    } else {
//		particlesJS.load('particles-js', '/particles-mobile.json', function() {
//		});
//    }

//});


class SkipBtns {
	constructor(container) {
		this.container = container
		if(!this.container) return

		this.btns = this.container.querySelectorAll('.btn')

		this.init()
	}

	bindAll() {
		["btnClickEvent"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}

	btnClickEvent(e) {
		let el = e.srcElement
		this.btnClick(el)
	}

	btnClick(el) {
		let target = el.dataset.target
		scrollToHash(target)
	}

	init() {
		this.bindAll()
		this.btns.forEach( (el) => {
			el.addEventListener('click', this.btnClickEvent)
		})
	}
}

const skipBtns = document.querySelector('.skip_nav')
const skip = new SkipBtns(skipBtns)