
function teamScroll() {
	setTimeout(function() {
		let horizontalWrapper = document.querySelector('.horizontal_wrapper')
		if(horizontalWrapper) {
			jQuery(window).trigger('resize')
			horizontalScrollInit()
		}
	}, 500)
}

function horizontalScrollInit() {	
	gsap.registerPlugin(ScrollTrigger);
		
	const sections = gsap.utils.toArray(".horizontal_section")
	let maxWidth = 0
	
	const getMaxWidth = () => {
		maxWidth = 0
		sections.forEach((section) => {
			maxWidth += section.offsetWidth
		})
	}
	getMaxWidth()
	
	ScrollTrigger.addEventListener("refreshInit", getMaxWidth);
	
	//console.log(maxWidth, sections, window.innerWidth)

	gsap.to(sections, {
		x: () => `-${maxWidth - window.innerWidth}`,
		ease: "none",
		scrollTrigger: {
			trigger: ".horizontal_wrapper",
			pin: true,
			scrub: 0.3,
			end: () => `+=${maxWidth}`,
			invalidateOnRefresh: true
		}
	})

	sections.forEach((sct, i) => {
		ScrollTrigger.create({
			trigger: sct,
			start: () => 'top top-=' + (sct.offsetLeft - window.innerWidth / 4 * 3) * (maxWidth / (maxWidth - window.innerWidth)),
			end: () => '+=' + sct.offsetWidth * (maxWidth / (maxWidth - window.innerWidth)),
			onEnter: () => { sct.classList.add('active') },
			onLeaveBack: () => { sct.classList.remove('active') },
			snap: 1 / (sct - 1)
		})
	})
}
