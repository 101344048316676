function getParameterByName( name ){
	name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]")
	const regexS = "[\\?&]"+name+"=([^&#]*)"
	const regex = new RegExp( regexS )
	let results = regex.exec( window.location.href )
	if( results == null )
		return ""
	else
		return decodeURIComponent(results[1].replace(/\+/g, " "))
}

function replaceText(toReplaceClass, utmName) {
	const toReplace = document.querySelectorAll(toReplaceClass)
	if(toReplace){
		const utmText = getParameterByName(utmName)
		const formField = document.querySelector('[data-name="term"] input')
		if(utmText) {
			toReplace.forEach( (replace) => {
				replace.innerHTML = utmText
			})
			formField.value = utmText
	
		} else {
			formField.value = 'Direct Entry'
		}
	}
}

//window.addEventListener("load", function () {
//	replaceText(".to_replace", "utm_term")
//})