function scrollText() {
	let slidingText = gsap.utils.toArray('.skr_text')

	slidingText.forEach(function( textArea, i) {
		let textSpan = textArea.querySelector('.scroller')
		let options = new Map()

		if(i % 2 == 0) {
			options = new Map()
			options.set('fromX', 95)
			options.set('toX', 0)
		} else {
			options = new Map()
			options.set('fromX', -50)
			options.set('toX', 45)
		}

		gsap.timeline({
			scrollTrigger: {
				trigger: textArea,
				start: "top bottom",
				end: "bottom top-=20%",
				scrub: 1
			}
		}).fromTo(textSpan, {
			xPercent: options.get('fromX')
		}, {
			xPercent: options.get('toX')
		})
	})
}