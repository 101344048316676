class RotatingWords {
	constructor() {
		this.wordsContainer = document.querySelector('.words')
		this.words = this.wordsContainer.querySelectorAll('i')
		this.wordCount = this.words.length
		this.currentWordIndex = 0
		this.paused = false

		this.init()
	}

	switchWords() {
		let visible = this.wordsContainer.querySelector('.visible')
		let nextWord
		if(this.currentWordIndex === (this.wordCount - 1)) {
			nextWord = this.words[0]
			this.currentWordIndex = -1
		} else {
			nextWord = this.words[this.currentWordIndex + 1]
		}
		visible.classList.remove('visible')
		visible.classList.add('hidden')
		nextWord.classList.remove('hidden')
		nextWord.classList.add('visible')
		this.currentWordIndex++
	}

	pause() {
		this.paused = true
	}

	play() {
		this.paused = false
	}

	animate() {
		if(this.paused === false) {
			this.switchWords()
		}
	}

	init() {
		setInterval(() => {
			this.animate()
		}, 3500)
	}
}


const words = document.querySelectorAll('.words')
let homeWords = ''
if(words.length > 0) {
	homeWords = new RotatingWords()
}
