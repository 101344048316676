jQuery(".posts_slick").slick({
	infinite: true,
	slidesToShow: 4,
	slidesToScroll: 1,
	dots: false,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 3000,
	pauseOnHover: false,
	pauseOnFocus: false,
	centerMode: true,
	centerPadding: '40px',
	variableWidth: true,
	//adaptiveHeight: true
})
