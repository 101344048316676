class MobileNav {
	constructor() {
		this.toggle = document.querySelector(".toggle")
		this.links = document.querySelector(".mobile_links")
		this.dropdowns = document.querySelectorAll(".mobile_links li.dropdown")
		this.backBtns = document.querySelector(".back")
		this.clickLinks = this.links.querySelectorAll('a:not(.dropdown-toggle)')

		this.init()
	}

	subClicks() {
		this.dropdowns.forEach(dropdown => {
			let linked = dropdown.querySelector('a')
			linked.addEventListener('click', function() {
				openChildNav(dropdown)
			} )
		})
	}

	navigate(link) {
		//let url = link.data('href')
		link.click()
	}

	toggleNav() {
		if(this.toggle.classList.contains('is-transitioning')) {
			return
		} else {
			this.toggle.classList.add('is-transitioning')
			this.toggle.classList.toggle('active')
			document.body.classList.toggle('noscroll')
			this.links.classList.toggle('active')
			this.dropdowns.forEach(dropdown => {
				dropdown.classList.remove('open')
			})
			var that = this
			setTimeout(function() {
				that.toggle.classList.remove('is-transitioning')
			}, 500)
		}
	}

	setupChildNav() {
		this.dropdowns.forEach(dropdown => {
			let links = dropdown.querySelector('ul')
			let back_btn = document.createElement("li")
			back_btn.setAttribute('class', 'back')
			links.append(back_btn)
			back_btn.innerHTML = back_btn.innerHTML + 'Back'
			this.updateBackBtns()
		})
	}

	updateBackBtns() {
		this.backBtns = document.querySelector(".back")
	}

	toggleChildNav(dropdown) {
		dropdown.classList.toggle('open')
	}

	onResize() {
		if(this.toggle.classList.contains('is-transitioning')) {
			return
		} else {
			this.toggle.classList.add('is-transitioning')
			this.toggle.classList.remove('active')
			document.body.classList.remove('noscroll')
			this.links.classList.remove('active')
			this.dropdowns.forEach(dropdown => {
				dropdown.classList.remove('open')
			})
			var that = this
			setTimeout(function() {
				that.toggle.classList.remove('is-transitioning')
			}, 500)
		}
	}
	
	bindAll() {
		["subClicks", "toggleNav", "toggleChildNav", "onResize"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}

	init() {
		this.bindAll()
		this.setupChildNav()
		this.toggle.addEventListener('mousedown', () => {
			this.toggleNav()
		}, false)
		this.dropdowns.forEach(dropdown => {
			dropdown.addEventListener('mousedown', () => {
				this.toggleChildNav(dropdown)
			})
		})
		this.clickLinks.forEach(link => {
			link.addEventListener('mousedown', () => {
				this.navigate(link)
				this.toggleNav()
			})
		})
		window.addEventListener('resize', this.onResize, false)
	}
}

const toggle = document.querySelector(".toggle")
if(toggle) new MobileNav()
