const SECONDS_PER_SLIDE = 8

class ImageSlider {
	constructor(container, slider, slides) {
		this.varContainer = container
		this.varSlider = slider
		this.varSlides = slides
		this.container = document.querySelector(this.varContainer)
		this.slider = this.container.querySelector(this.varSlider)
		this.slides = this.slider.querySelectorAll(this.varSlides)
		this.slidesUpdated = this.slider.querySelectorAll(this.varSlides)
		this.slidesArray = []

		this.animationWidth = 0

		this.visibleSlides = 0

		this.sliderWidth = 0
		this.sliderHeight = 0
		this.slideCount = 0
		this.speed = 0

		this.init()
	}

	countSlides() {
		this.slideCount = this.slides.length
		this.setSpeed()
	}

	setSpeed() {
		this.speed = this.slideCount * SECONDS_PER_SLIDE
		this.container.style.setProperty('--animationLength', this.speed + 's');
	}

	setupSlider() {
		this.countSlides()
		let visibleArea = this.container.offsetWidth
		this.slides.forEach((el) => {
			let slideWidth = el.offsetWidth
			this.animationWidth += slideWidth
			this.slidesArray.push(el.cloneNode(true))
			if(visibleArea > 0) {
				visibleArea -= slideWidth
				this.visibleSlides++
			}
		})
		this.addSlides()
		this.container.style.setProperty('--animationWidth', this.animationWidth + 'px');
		this.slider.classList.add('animate')
	}

	addSlides() {
		this.slidesArray.slice(0, this.visibleSlides).forEach(slide => {
			this.slider.append(slide)
		})
		this.slidesUpdated = this.slider.querySelectorAll(this.varSlides)
		this.slidesUpdated.forEach((el) => {
			this.sliderWidth += el.offsetWidth
		})
		this.slider.style.width = this.sliderWidth + 'px'
	}

	onResize() {
		let s = document.querySelector(this.varContainer + ' ' + this.varSlider)
			s.classList.add('resizing')
		this.slider.innerHTML = ''
		this.slides.forEach((el) => {
			this.slider.append(el)
		})
		this.animationWidth = 0
		this.sliderWidth = 0
		this.visibleSlides = 0
		this.setupSlider()
		s.classList.remove('resizing')
	}

	bindAll() {
		["onResize"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}

	init() {
		this.bindAll()
		this.setupSlider()
		window.addEventListener("resize", this.onResize)
	}
}

const slider = document.querySelectorAll('.image_slider')
if(slider.length > 0) {
	new ImageSlider('.image_slider', '.animation', 'div')
}
