const lerp = (a, b, n) => (1 - n) * a + n * b;

class Cursor {
	constructor() {
		this.cursorCont = document.querySelector('.cursor_container')
		this.cursorElem = [
			document.querySelector('.cursor_1'),
			document.querySelector('.cursor_2')
		]
		this.target = { x: 0.5, y: 0.5 } // mouse position
		this.cursor = { x: 0.5, y: 0.5 } // cursor position
		this.speed = 0.3 // speed
		this.hoverTriggers = new Set([
			...document.querySelectorAll('a'),
			...document.querySelectorAll('button'),
			...document.querySelectorAll('.btn'),
			...document.querySelectorAll('.typeform'),
			...document.querySelectorAll('[data-featherlight]'),
			...document.querySelectorAll('.playIndicator'),
			...document.querySelectorAll('video'),
			...document.querySelectorAll('.icon'),			
		])
		this.colorChange = new Set([
			...document.querySelectorAll('.hustlr'),
		])
		this.iframes = new Set([
			...document.querySelectorAll('iframe'),
			...document.querySelectorAll('.media_container')
		])
		this.init()
	}

	bindAll() {
		["onMouseMove", "onMouseDown", "onMouseUp", "triggersMouseOver", "triggersMouseLeave", "framesMouseOver", "framesMouseLeave", "triggersColourChangeOver", "triggersColourChangeLeave", "render"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}

	onMouseMove(e) {
		this.target.x = e.clientX / window.innerWidth
		this.target.y = e.clientY / window.innerHeight
		if(!this.raf) {
			this.raf = requestAnimationFrame(this.render)
		}
	}

	onMouseDown() {
		this.cursorCont.style.setProperty("--cursor-state", 'click')
	}

	onMouseUp() {
		this.cursorCont.style.setProperty("--cursor-state", 'free')
	}

	triggersMouseOver() {
		this.cursorElem[0].classList.add('hover')
	}
	
	triggersMouseLeave() {
		this.cursorElem[0].classList.remove('hover')
	
	}
	framesMouseOver() {
		this.cursorElem[0].classList.add('iframe')
		this.cursorElem[1].classList.add('iframe')
	}
	
	framesMouseLeave() {
		this.cursorElem[0].classList.remove('iframe')
		this.cursorElem[1].classList.remove('iframe')
	}

	triggersColourChangeOver() {
		this.cursorElem[0].classList.add('pinkbg')
		this.cursorElem[1].classList.add('pinkbg')
	}

	triggersColourChangeLeave() {
		this.cursorElem[0].classList.remove('pinkbg')
		this.cursorElem[1].classList.remove('pinkbg')
	}

	render() {
		this.cursor.x = lerp(this.cursor.x, this.target.x, this.speed)
		this.cursor.y = lerp(this.cursor.y, this.target.y, this.speed)
	
		this.cursorCont.style.setProperty("--cursor-x", this.cursor.x)
		this.cursorCont.style.setProperty("--cursor-y", this.cursor.y)

		const delta = Math.sqrt(
			Math.pow(this.target.x - this.cursor.x, 2) + Math.pow(this.target.y - this.cursor.y, 2)
		)

		if(delta < 0.001) {
			cancelAnimationFrame(this.raf)
			this.raf = null
			return
		}

		this.raf = requestAnimationFrame(this.render)
	}

	init() {
		this.bindAll()
		window.addEventListener("mousemove", this.onMouseMove)
		document.addEventListener("mousedown", this.onMouseDown)
		document.addEventListener("mouseup", this.onMouseUp)
		this.hoverTriggers.forEach(triggers => {
			triggers.addEventListener("mouseover", () => {
				this.triggersMouseOver()
			})
			triggers.addEventListener("mouseleave", () => {
				this.triggersMouseLeave()
			})
		})
		this.colorChange.forEach(section => {
			section.addEventListener("mouseover", () => {
				this.triggersColourChangeOver()
			})
			section.addEventListener("mouseleave", () => {
				this.triggersColourChangeLeave()
			})
		})
		this.raf = requestAnimationFrame(this.render)
	}
}

const parallelCursor = new Cursor()