function checkScroll() {
	let elements = document.querySelectorAll('.scrollCheck')	
	elements.forEach((element) => {
		let area = element.dataset.area,
			elem = element.dataset.elem,
			type = element.dataset.type,
			change = element.dataset.change
		checkScrollInner(area, elem, type, change)
	})
	
	
	var videoAreaSec = document.getElementById("aboutHeaderVideo")
	if (videoAreaSec) {
		checkScrollInner('#aboutHeaderVideo', '#scrollingText', "text", "videoFixed")
		starWarsScroll()
	}

	var gameOnArea = document.getElementById("ctaBanner")
	if (gameOnArea) {
		var gameOnVideo = document.getElementById("gameOnVideo")
		if (window.innerWidth < 991) {
			gameOnVideo.pause()	
		}
	}
}

function checkScrollInner(area, elem, type, classAdd) {

	let this_area = document.querySelector(area),
		this_elements = document.querySelectorAll(elem)

	const scrollDistance = document.documentElement.scrollTop || document.body.scrollTop
	const windowHeight = window.innerHeight

	var adminBar = document.getElementById("wpadminbar")
		? document.getElementById("wpadminbar").offsetHeight
		: 0

	var y = this_area.offsetTop,
	h = this_area.offsetHeight

	this_elements.forEach((this_element, i) => {	
		if(type == 'service') {
			y += this_element.offsetTop
			h += this_element.offsetHeight
		}

		var vTop = y - windowHeight,
		vBottom = y + h

		if (type == "text") {
			var hE = this_element.offsetHeight
			vBottom = hE + adminBar
		}

		if(type == "team") {
			
			if(vTop > (scrollDistance - windowHeight)) {
				//BEFORE
				this_element.classList.remove(classAdd)
				this_element.classList.remove('fixed_bottom')
				this_element.classList.add('fixed_top')
			} else if((scrollDistance) > (vBottom - windowHeight) ) {
				// AFTER
				this_element.classList.remove(classAdd)
				this_element.classList.remove('fixed_top')
				this_element.classList.add('fixed_bottom')
			} else {
				// FIXED
				this_element.classList.remove('fixed_top')
				this_element.classList.remove('fixed_bottom')
				this_element.classList.add(classAdd)
			}
		}

		if (vTop < scrollDistance && scrollDistance < vBottom) {
			if (type == "video") {
				this_element.play()
			}
			if (type == "neon") {
				this_element.classList.add(classAdd)
			} else if (type == "slider") {
				this_element.classList.add(classAdd)
				this_element.classList.remove('paused')
				if(homeWords) { homeWords.play() }
			} else if (type == "service") {
				this_element.classList.add(classAdd)
			} else if (type == "text") {
				this_area.classList.add(classAdd)
			} else if (type == "pop") {
				this_element.classList.add(classAdd)
			} else if (type == "team") {
				// DO NOTHING
			} else {
				this_area.classList.add(classAdd)
			}
		} else {		
			if (type == "video") {
				this_element.pause()
			} else if (type == "neon") {
				this_element.classList.remove(classAdd)
			} else if (type == "slider") {
				this_element.classList.remove(classAdd)
				this_element.classList.add('paused')
				if(homeWords) { homeWords.pause() }
			} else if (type == "service") {
				if(vTop > scrollDistance) {
					this_element.classList.remove(classAdd)
				}
			} else if (type == "pop") {
				this_element.classList.remove(classAdd)
			} else if (type == "team") {
				// DO NOTHING
			} else {
				this_area.classList.remove(classAdd)
			}
		}
	})
}


function starWarsSize() {
	var text = document.getElementById("starWarsText")
	if(text) {
		const newHeight = text.children[0].offsetHeight + (window.innerHeight / 2);
		document.getElementById("scrollingText").style.height = newHeight + 'px'
	}
}
function starWarsScroll() {
	var text = document.getElementById("starWarsText")
	if(text) {
		let scrollPercent = (window.innerHeight * 2) - document.documentElement.scrollTop
		text.firstElementChild.style.top = scrollPercent + "px"
	}
}

function aboutVideoSet() {
	let videoAreaSec = document.getElementById("aboutHeaderVideo")
	if(videoAreaSec) {
		window.scroll(0, 1)
		window.scroll(0, 0)
	}
}


window.addEventListener("scroll", checkScroll, { passive: true })
window.addEventListener("resize", checkScroll, { passive: false })
window.addEventListener("load", checkScroll, { passive: false })

window.addEventListener("resize", starWarsSize, { passive: false })
window.addEventListener("load", starWarsSize, { passive: false })

window.addEventListener("load", aboutVideoSet, { passive: false} )